import { React, Text, View, Page, Spinner, useState } from 'lib'
import { Theme } from 'app'
import { useTimeout } from 'beautiful-react-hooks'


const NotFound = () => {
  const [loading, setLoading] = useState(true)

  useTimeout(() => {
    if (loading) {
      setLoading(false)
    }
  }, 3000)


  return (
    <Page center whiteBackground>
      <View style={styles.wrapper}>
        {loading ? (
          <Spinner/>
        ) : (
          <>
            <Text variant={'h3'}>Not found</Text>
            <Text variant={['p1', 'marginTop:2', 'textCenter']}>Oops! The page you're trying to access cannot be found.</Text>
          </>
        )}
      </View>
    </Page>
  )
}

const styles = {
  wrapper: {
    minHeight: '100vh',
    // marginTop: `calc(${Theme.values.headerMenuHeight} + ${Theme.spacing(4)}px)`,
    ...Theme.flex,
    ...Theme.center,
    flexDirection: 'column',
    width: '100%',
  },
}

export default NotFound
